<template>
  <el-dialog v-model="rawDialog" :title="rawDataTitle" custom-class="cus-cont">
    <div class="code-view">
      {{ rawData }}
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="rawDialog = false">{{
          $t.translate("BTN_CLOSE")
        }}</el-button>
      </span>
    </template>
  </el-dialog>

  <div>
    <div class="container-fluid pt-7 pb-5">
      <el-collapse class="expand-main mb-3 shadow">
        <el-collapse-item class="expand-item">
          <template #title>
            <div class="row pl-3">
              <div class="col-12 d-flex align-items-center">
                <i class="fas fa-search"></i>
                <h3 class="mb-0 ml-2">{{ $t.translate("LBL_SEARCH") }}</h3>
              </div>
            </div>
          </template>
          <card shadow type="secondary" class="">
            <div class="">
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.CreatedBy"
                    :label="$t.translate('LBL_CREATED_BY')"
                    input-classes="form-control-alternative"
                  />
                </div>
              </div>
              <div class="row mt-1 mb-2">
                <div class="col-lg-6">
                  <el-button type="primary" @click="filterAction">
                    {{ $t.translate("BTN_SEARCH") }}
                  </el-button>
                  <el-button type="primary" class="ml-3" @click="resetParams">
                    {{ $t.translate("BTN_RESET") }}
                  </el-button>
                </div>
              </div>
            </div>
          </card>
        </el-collapse-item>
      </el-collapse>

      <div class="shadow bg-white p-around">
        <Table
          :default-sort="{ prop: Filter.SortBy, order: Filter.SortOrder }"
          @sort-change="sortChange"
          url="/action/list"
          :query="{ ...Filter }"
          ref="filttable"
          height="68vh"
        >
          <el-table-column
            :label="$t.translate('LBL_EVENT')"
            min-width="125"
            class-name="pl-1"
          >
            <template #default="scope">
              {{ $h.actionMapping(scope.row.Action) }}
            </template>
          </el-table-column>

          <el-table-column :label="$t.translate('LBL_DATA')" min-width="140">
            <template #default="scope">
              <div v-if="!scope.row.ViewRaw" style="white-space: pre-line">
                {{ scope.row.RawDataDisplay }}
              </div>

              <el-link
                href="javascript:;"
                @click="viewRaw(scope.row)"
                v-if="scope.row.ViewRaw"
                type="warning"
                >{{ $t.translate("BTN_VIEW_RAW_DATA") }}</el-link
              >
            </template>
          </el-table-column>

          <el-table-column :label="$t.translate('LBL_CREATED_BY')" min-width="140">
            <template #default="scope">
              {{ $h.getFullName(scope.row.User) }}
              <strong style="display: block" v-if="scope.row.User.Organization">
                {{ $h.formatOrgBranch(scope.row.User.Organization) }}
              </strong>
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="LastCreated"
            :label="$t.translate('LBL_CREATED_DATE')"
            min-width="140"
          >
            <template #default="scope">
              {{ $h.formatDateTime(scope.row.LastCreated) }}
            </template>
          </el-table-column>

          <el-table-column :label="$t.translate('LBL_STATUS')" min-width="100">
            <template #default="scope">
              <el-tag type="success" v-if="scope.row.ActionLogId" disable-transitions>{{
                $t.translate("LBL_SUCCESS")
              }}</el-tag>
            </template>
          </el-table-column>
        </Table>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/Table.vue";
import { reactive, ref, onMounted } from "vue";
import { get, isValidResponse } from "@/utils/axios";
import { extendedConfigs } from "@/utils/configs";
import { helpers } from "@/utils/helpers";
import _ from "lodash";

export default {
  components: { Table },
  setup() {
    let fieldsToSkip = ["SortBy", "SortOrder"];
    let filttable = ref("filttable");

    let Filter = reactive({
      CreatedBy: "",
      SortBy: "LastCreated",
      SortOrder: "descending",
    });

    let filterAction = async () => {
      await filttable.value.handleCurrentChange(1, Filter);
    };

    const sortChange = ({ prop, order }) => {
      Filter.SortBy = prop;
      Filter.SortOrder = order;
      filterAction();
    };

    const filterNow = (page, filter = null) => {
      filttable.value.handleCurrentChange(page, filter);
    };

    const resetParams = () => {
      for (let key in Filter) {
        if (fieldsToSkip.indexOf(key) > -1) continue;
        Filter[key] = "";
      }
      filterNow(1, Filter);
    };

    let rawDialog = ref(false);
    let rawData = ref({});
    let rawDataTitle = ref("");

    const viewRaw = ({ Action, RawDataContent }) => {
      rawDataTitle.value = helpers.actionMapping(Action);
      rawData.value = JSON.stringify(RawDataContent, null, 4);

      if (rawData.value == "{}") {
        rawData.value = "No data found";
      }

      rawDialog.value = true;
    };

    return {
      rawDialog,
      rawData,
      rawDataTitle,
      Filter,
      viewRaw,
      sortChange,
      filterNow,
      resetParams,
      filterAction,
      filttable,
    };
  },
};
</script>
<style></style>
